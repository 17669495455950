import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { UserContext } from '../../context/UserContext';
import { NavLink } from 'react-router-dom';
import DeleteUserModal from './DeleteUserModal';
import { FavoriteContext } from '../../context/FavoriteContext';

// Favorites and User menu in the navbar
class UserControls extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            showDeleteUserModal: false
        }
    }

    showModal() {
        this.setState({ showDeleteUserModal: true });
    }

    hideModal() {
        this.setState({ showDeleteUserModal: false });
    }

    render() {
        const { t } = this.props;
        const userInfo = this.context.value;

        if (userInfo) {
            const { userName } = JSON.parse(userInfo);

            return (
                <>
                    <NavItem className="dropdown btn-icon btn-icon-profile">
                        { /* Whether a star has to be yellow or not is saved in a context */
                        }
                        <FavoriteContext.Consumer>
                            {({ favValue, setFavValue }) => favValue
                                ? <NavLink to="/fav" className="navbar-button-image fa nav-link"><FontAwesomeIcon icon="star" color="#f2b34d" /* theme-orange */ /></NavLink>
                                : <NavLink to="/fav" className="navbar-button-image fa nav-link"><FontAwesomeIcon icon="star" /></NavLink>
                            }
                        </FavoriteContext.Consumer>
                    </NavItem>

                    <UncontrolledDropdown className="dropdown btn-icon btn-icon-profile" nav inNavbar>
                        <DropdownToggle className="navbar-button-image fa" nav>
                            <FontAwesomeIcon icon="user" />
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem header>
                                {t('PAGE.PROFILE.LOGGEDINFOR') + ' ' + userName}
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem className="nav-dropdown-removeuser" onClick={this.showModal.bind(this)}>
                                {t('PAGE.PROFILE.REMOVEUSERBTN')}
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    {this.state.showDeleteUserModal ? <DeleteUserModal callback={this.hideModal.bind(this)} /> : null}
                </>
            );
        } else {
            return null;
        }
    }
}

export default withTranslation()(UserControls);