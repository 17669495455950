import React, { Component } from 'react';
import logoNL from '../../images/logo.png';
import logoEN from '../../images/logo-en.png';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { Link, NavLink } from 'react-router-dom';
import { LanguageEngSetting } from '../../constants/Constants';
import { withRouter } from 'react-router';

class Banner extends Component {
    
    setBannerImage() {
        const images = [
            '00', '01', '05', '06', '07', '10', '11', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22'
        ];

        console.debug("this.props.location.pathname", this.props.location.pathname);
        const customImages = { '/': '22', '/info': '23' };
        const imageSuffix = this.props.location.pathname in customImages
            ? customImages[this.props.location.pathname]
            : images[Math.floor(Math.random() * images.length)];

        this.imageClass = "header-image-" + imageSuffix;
    }

    render() {
        this.setBannerImage();

        const { t } = this.props;
        const lang = i18n.language;

        // get logo for current language, default is nl
        let gssLogo;
        if (lang === LanguageEngSetting) {
            gssLogo = <img className="image-logo" src={logoEN} alt="Logo EN" />;
        } else {
            gssLogo = <img className="image-logo" src={logoNL} alt="Logo NL" />;
        }

        return (
            <div id="page-header-image" className={`${this.imageClass} container JustifiedContentBannerText`} >
                <div className="container JustifiedContentBannerText" id="page-header">
                    <div className="BannerTextSmaller">
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 banner_nav_container">
                            <Link to="/">
                                {gssLogo}
                            </Link>
                        </div>
                        <div id="main-menu-container" className="col-xs-12 col-sm-8 col-md-8 col-lg-8 banner_nav_container">
                            <ul className="nav banner_nav_group">
                                <li className="banner_nav_item">
                                    <NavLink to="/explore">{t('PAGE.PRODUCTGROUPEXPLORE.HEADER')}</NavLink>
                                </li>
                                <li className="banner_nav_item">
                                    <NavLink to="/act">{t('PAGE.ACTIONPERSPECTIVE.HEADER')}</NavLink>
                                </li>
                                <li className="banner_nav_item">
                                    <NavLink to="/explore/assess/country">{t('PAGE.COUNTRYFACTSHEET.HEADER')}</NavLink>
                                </li>
                                <li className="banner_nav_item">
                                    <NavLink to="/info">{t('PAGE.INFO.HEADER')}</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withRouter(Banner));