import React from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { NavHeaders } from '../../constants/NavHeaders';


function Breadcrumbs(props) {
    const location = useLocation();
    const { pathname } = location;

    // homepage does not have breadcrumbs
    if (pathname === '/') {
        return (null);
    }

    // home is always there when showing breadcrumbs
    const { t } = props;
    const home = t('PAGE.HOME.HEADER');

    // split the url and remove the empty values
    const paths = pathname.split('/');
    const filteredPaths = paths.filter(path => (path !== "" && isNaN(path)));

    // if no paths return null
    if (filteredPaths.length == 0) {
        return (null);
    }

    // get navheaders for translations
    const headerInfo = NavHeaders();
    const lastPath = filteredPaths.length - 1;

    // check whether all the breadcrumbs are translateable
    let allKeysPresent = true;
    for (const path of filteredPaths) {
        if (!headerInfo[path]) {
            allKeysPresent = false;
            break;
        }
    }

    // if one of the many paths is not available 
    // for translation, then don't show breadcrumbs
    if (!allKeysPresent) {
        return (null);
    }

    // variable used to build the path
    // to +1 levels of breadcrumbs
    let previousPath = '';

    return (
        <Container id="breadcrumbContainer">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/">{home}</Link>
                </BreadcrumbItem>
                {
                    filteredPaths.map((path, i) => {
                        previousPath += '/' + path;

                        return i === lastPath
                            ? <BreadcrumbItem active={true} key={i}>{t(headerInfo[filteredPaths[i]])}</BreadcrumbItem>
                            : <BreadcrumbItem key={i}><Link to={previousPath}>{t(headerInfo[filteredPaths[i]])}</Link></BreadcrumbItem>
                    })
                }
            </Breadcrumb>
        </Container>
    );
}

export default withTranslation()(Breadcrumbs);