/** Function for translating Breadcrumbs */
export function NavHeaders() {
    var headers = {};

    headers['info'] = "PAGE.INFO.HEADER";
    headers['fav'] = "PAGE.FAVORITES.HEADER";
    headers['explore'] = "PAGE.PRODUCTGROUPEXPLORE.HEADER";
    headers['assess'] = "PAGE.PRODUCTGROUPCRITICIZE.HEADER";
    headers['act'] = "PAGE.ACTIONPERSPECTIVE.HEADER";
    headers['resource'] = "PAGE.RAWMATERIALFACTSHEET.HEADER";
    headers['productgroup'] = "PAGE.PRODUCTGROUPFACTSHEET.HEADER";

    headers['confirm'] = "PAGE.CONFIRM.HEADER";
    headers['biodiversity'] = "PAGE.BIODIVERSITY.HEADER";
    headers['copper'] = "PAGE.BIODIVERSITY.COPPERHEADER";
    headers['gold'] = "PAGE.BIODIVERSITY.GOLDHEADER";
    headers['uranium'] = "PAGE.BIODIVERSITY.URANIUMHEADER";
    headers['cotton'] = "PAGE.BIODIVERSITY.COTTONHEADER";
    headers['rice'] = "PAGE.BIODIVERSITY.RICEHEADER";

    return headers;
}