import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Spinner, Alert } from 'reactstrap';
import AccountService from '../../services/AccountService';
import { Link } from 'react-router-dom';

const api = new AccountService();

// Confirm email of user
class Confirm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            hasError: false,
            errorText: ''
        }

        // confirmation id
        this.confirmationid = this.props.match.params.confirmationid;
    }

    componentDidMount() {
        this.confirmAccount();
    }

    // get message from response if there are any errors
    setErrorMessage(text) {
        this.setState({ errorText: text });
    }

    // action to confirm email
    async confirmAccount() {
        const confirmationHasError = await api.confirmMail(this.confirmationid, this.setErrorMessage.bind(this));

        this.setState({ hasError: confirmationHasError, isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="text-center">
                    <Spinner color="primary" />
                </div>
            );
        } else {
            const { t } = this.props;

            return (
                <div>
                    {
                        this.state.hasError
                            ? <Alert color="danger">
                                {this.state.errorText ? this.state.errorText : t('PAGE.HOME.REGISTERFAILED')}
                            </Alert>
                            : <Alert color="success">
                                {t('PAGE.HOME.REGISTERRESULT')}
                            </Alert>
                    }

                    <Link to='/'>
                        <button
                            type="button"
                            className="btn btn-primary btn-wide">
                            {t('PAGE.LOGIN.REDIRECTHOME')}
                        </button>
                    </Link>

                </div>
            );
        }
    }
}

export default withTranslation()(Confirm);