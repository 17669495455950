import { Component } from "react";
import Api from './Api';
import { UserContext } from '../context/UserContext';

class AuthenticationService extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
    }

    async login(username, password, func, updateStatus) {
        const payload = `grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;

        var errorState = false;

        const header = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        await Api.post('/token', payload, header).then(res => {
            // get response data
            const response = {
                "token": res.data.access_token,
                "expires": res.data[".expires"],
                "userName": res.data.userName
            };
            const jsonResponse = JSON.stringify(response);
        
            errorState = false;
            localStorage.setItem('user', res.data.userName);
            localStorage.setItem('user_expiration', res.data[".expires"]);
            // write to context
            func(jsonResponse);
        }).catch(err => {
            console.error('encountered an error:', err);
            const { error_description } = err.response?.data ?? err;
            localStorage.setItem('user','');
            localStorage.setItem('user_expiration','');

            func('');

            errorState = true;
            updateStatus(error_description, errorState);
        });

        return !errorState;
    }

    logout(func) {
        Api.get('/api/cookie/delete/')
            .then(_ => { console.log("logout response") });
        localStorage.setItem('user', '');
        localStorage.setItem('user_expiration','');
        localStorage.setItem('fav', '');
        func('');
    }

    async register(email, username, password, confirmPassword, updateStatus) {
        const payload = `Email=${encodeURIComponent(email)}&Username=${encodeURIComponent(username)}&Password=${encodeURIComponent(password)}&ConfirmPassword=${encodeURIComponent(confirmPassword)}`;

        var errorState = false;

        await this.postRequest('/api/account/register', payload).then(res => {

            // get response message
            updateStatus('PAGE.GLOBAL.REGISTERSUCCESS', errorState);
        }).catch(err => {
            console.error('encountered an error:', err);
            errorState = true;
            updateStatus('PAGE.GLOBAL.UNKNOWNERROR', errorState);
        });

        return errorState ? false : true;
    }

    async forgotuser(email, updateStatus) {
        console.log('forgotUser called');
        const payload = `Email=${encodeURIComponent(email)}`;

        await this.postRequest('/api/account/forgotuser', payload)
            .then(res => {
                console.log('forgotUser: server response succesful.');
                updateStatus('PAGE.GLOBAL.FORGOTSUCCESS', false);
            })
            .catch(err => {
                console.error('forgotUser: error encountered.', err);
                updateStatus('PAGE.GLOBAL.UNKNOWNERROR', true);
            });
    }

    async forgotpass(username, updateStatus) {
        console.log('forgotPass called');
        const payload = `Username=${encodeURIComponent(username)}`;

        await this.postRequest('/api/account/forgotpass', payload)
            .then(res => {
                console.log('forgotPass: server response succesful.');
                updateStatus('PAGE.GLOBAL.FORGOTSUCCESS', false);
            })
            .catch(err => {
                console.error('forgotPass: error encountered.', err);
                updateStatus('PAGE.GLOBAL.UNKNOWNERROR', true);
            });
    }

    async deleteuser(username, token, updateStatus) {
        const header = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        var errorState = false;
        const urlPost = `api/account/DeleteUser/${username}`;

        await Api.get(urlPost, header).then(res => {
            // get response message
            updateStatus('PAGE.DELETEUSER.ACCOUNTREMOVED', errorState);
        }).catch(err => {
            console.error('encountered an error:', err);
            errorState = true;
            updateStatus('PAGE.DELETEUSER.REMOVEACCOUNTOKENERROR', errorState);
        });

        return errorState ? false : true;
    }

    async postRequest(url, payload) {
        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
        };

        return fetch(url, {
            credentials: "include",
            method: "POST",
            headers: headers,
            body: payload,
        });
    }
}

export default AuthenticationService;