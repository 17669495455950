import Cookies from 'js-cookie';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { Container, Navbar, NavItem } from 'reactstrap';
import { DataYear, Eu27Data, Eu27DataCookieValue, ImportExportDataCookie, LanguageCookie, LanguageEngCookieValue, LanguageNlCookieValue, NlData, NlDataCookieValue } from '../../constants/Constants';
import i18n from '../../i18n';
import flagEU from '../../lib/region-flags/png/EU.png';
import flagGB from '../../lib/region-flags/png/GB.png';
import flagNL from '../../lib/region-flags/png/NL.png';
import AuthenticationButton from '../authentication/AuthenticationButton';
import InfoPopover from '../generic/InfoPopover';
import Feedback from './Feedback';
import SessionEnd from './SessionEnd';
import UserControls from './UserControls';

class NavMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            datatag: Cookies.get(ImportExportDataCookie) === Eu27DataCookieValue ? Eu27Data : NlData,
            oldLanguageCookie: Cookies.get(LanguageCookie),
            oldDataCookie: Cookies.get(ImportExportDataCookie)
        }

        this.languageOptions = [
            {
                value: LanguageNlCookieValue,
                label: 'Nederlands',
                image: flagNL,
                class: 'language-dutch'
            },
            {
                value: LanguageEngCookieValue,
                label: 'English',
                image: flagGB,
                class: 'language-english'
            }
        ];

        this.dataSourceOptions = [
            {
                value: NlDataCookieValue,
                label: 'NL',
                image: flagNL,
                class: 'language-dutch',
                tag: NlData
            },
            {
                value: Eu27DataCookieValue,
                label: 'EU-27',
                image: flagEU,
                class: 'language-dutch',
                tag: Eu27Data
            }
        ];
    }

    componentDidMount() {
        if (typeof (this.state.oldDataCookie) === 'undefined') {
            Cookies.set(ImportExportDataCookie, NlDataCookieValue, { secure: true });
        }
        if (typeof (this.state.oldLanguageCookie) === 'undefined') {
            Cookies.set(LanguageCookie, LanguageNlCookieValue, { secure: true });
        }
    }

    handleInputChangeDataSource = (option) => {
        const chosenDataSource = option.value;

        Cookies.set(ImportExportDataCookie, chosenDataSource, { secure: true });
        this.setState({ datatag: chosenDataSource === Eu27DataCookieValue ? Eu27Data : NlData });
    }

    handleInputChangeLanguage = (option) => {
        const chosenLanguage = option.value;

        Cookies.set(LanguageCookie, chosenLanguage);
        this.setState({
            oldLanguageCookie: chosenLanguage
        });

        i18n.changeLanguage(chosenLanguage);
    };

    render() {
        const { t } = this.props;

        const dropdownIndicatorStyles = {
            control: styles => ({
                ...styles,
                borderWidth: 0,
                boxShadow: 0
            }),
            option: styles => ({
                ...styles,
                padding: 0
            }),
            dropdownIndicator: styles => ({
                ...styles,
                padding: 0,
                height: 15
            })
        };

        return (
            <>
                <header>
                    <Navbar id="page-Navbar" className="navbar-expand-sm navbar-top navbar-toggleable-sm ng-white box-shadow mb-3 bg-dark" dark fixed="top">
                        <Container>
                            <div className="d-sm-inline-flex flex-sm-row-reverse navbar-brand">
                                <ul className="navbar-nav flex-grow">
                                    <div>{t('PAGE.GLOBAL.DATASOURCE')}</div>
                                    <Select
                                        className="navmenu-dropdown-custom-ui"
                                        classNamePrefix="datasource-choice"
                                        value={this.dataSourceOptions.filter(option => option.tag === this.state.datatag)}
                                        onChange={this.handleInputChangeDataSource}
                                        options={this.dataSourceOptions}
                                        formatOptionLabel={source => (
                                            <img className={source.class} src={source.image} alt={source.label} />
                                        )}
                                        styles={dropdownIndicatorStyles}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#56aade', // soft-blue
                                            }
                                        })}
                                    />
                                    <div className="navbar-dataselector">{this.state.datatag} ({DataYear})</div>
                                </ul>
                            </div>
                            <div className="d-sm-inline-flex flex-sm-row-reverse navbar-brand">
                                <ul className="navbar-nav flex-grow">
                                    <div>{t('PAGE.GLOBAL.DISPLAYLANGUAGE')}</div>
                                    <Select
                                        className="navmenu-dropdown-custom-ui"
                                        classNamePrefix="language-choice"
                                        value={this.languageOptions.filter(option => option.value === this.state.oldLanguageCookie)}
                                        onChange={this.handleInputChangeLanguage}
                                        options={this.languageOptions}
                                        formatOptionLabel={language => (
                                            <img className={language.class} src={language.image} alt={language.label} />
                                        )}
                                        styles={dropdownIndicatorStyles}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#56aade', // soft-blue
                                            }
                                        })}
                                    />

                                    <UserControls />

                                    <NavItem>
                                        <AuthenticationButton />
                                    </NavItem>
                                    <InfoPopover
                                        transkey="PAGE.GLOBAL.REGISTRATIONADVANTAGESPOPOVER"
                                        extraClass="indicator-info"
                                        content={t("PAGE.GLOBAL.REGISTRATIONADVANTAGESPOPOVER")}
                                        id="registerAdvantages"
                                        placement="bottom"
                                    />
                                </ul>
                            </div>
                        </Container>
                    </Navbar>

                </header>
                <Feedback />
                <SessionEnd />
            </>
        );
    }
}

export default withTranslation()(NavMenu);